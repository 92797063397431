export default {
  "card-header": "qMA",
  "counterparty-name": "qMY",
  "header-section": "qMh",
  "pan-section": "qMf header-section body-2",
  "infobox": "qMK header-section",
  "see-card-button": "qMG",
  "card-identifier-section": "qMr",
  "card-icon": "qMb",
  "card-status": "qMy",
  "nickname": "qMU body-1",
  "pan": "qMj body-2"
};
