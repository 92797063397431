export default {
  "savings-account": "cyh",
  "closed": "cyf",
  "badge": "cyK",
  "subtitle": "cyG",
  "amount": "cyr",
  "processing": "cyb",
  "gain": "cyy",
  "active": "cyU",
  "progress": "cyj",
  "progress-bar": "cyW",
  "progress-text": "cyu",
  "info": "cUS",
  "menu-item": "cUc body-2",
  "close-account": "cUq"
};
