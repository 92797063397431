export default {
  "header": "qSL",
  "subtitle": "qST body-2",
  "date": "qSz",
  "subtitle-error": "qSa",
  "card": "qSH",
  "card-header": "qSO",
  "line-placeholder": "qSm",
  "post-closing-list": "qSp",
  "post-closing-container": "qSx",
  "post-closing-list-element": "qSw",
  "free-trial-disclaimer": "qSN",
  "description": "qSv body-2",
  "mw-100": "qSo",
  "addon-overview": "qSn",
  "addons-container": "qSi"
};
