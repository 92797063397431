export default {
  "supplier-selector-wrapper": "qqp",
  "select-supplier": "qqx",
  "dropdown": "qqw",
  "iban": "qqN",
  "card": "qqv body-2",
  "card-content": "qqo",
  "add-btn": "qqn btn btn--square btn--icon-only",
  "archived-card": "qqi",
  "title": "qqA body-1",
  "archived-title": "qqY",
  "edit-supplier-tooltip": "qqh",
  "add-supplier": "qqf btn btn--tertiary",
  "error": "qqK caption",
  "form-title": "qqG title2",
  "dropdown-menu": "qqr",
  "supplier-invoice-detail-menu": "qqb",
  "item-divider": "qqy",
  "menu-item": "qqU body-2 dropdown-overlay",
  "disabled": "qqj",
  "iban-error": "qqW",
  "iban-disclaimer": "qqu"
};
