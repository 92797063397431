export default {
  "invoice-sidebar": "cWZ",
  "body": "cWR",
  "box": "cWQ",
  "box-header": "cWe",
  "sidebar-box": "cWB",
  "border-top": "cWE",
  "row": "cWd",
  "greyed-out": "cWI",
  "strike-through": "cWt"
};
