import { template as template_843f424469a1457d903d5917cebe863b } from "@ember/template-compiler";
import { hash } from '@ember/helper';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import Tooltip from '@qonto/ui-kit/components/tooltip';
import { Button, DropdownButton, SkeletonLoader } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { t } from 'ember-intl';
import not from 'ember-truth-helpers/helpers/not';
import or from 'ember-truth-helpers/helpers/or';
import ReactBridge from 'qonto/components/react-bridge';
import ReactBridgeWithProviders from 'qonto/components/react-bridge-with-providers';
import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';
import { currentStep, isCurrentStepToApprove, isCurrentStepToPay, userIsCurrent } from 'qonto/utils/approval-workflow-state';
export default class DetailsDropdownCta extends Component {
    @service
    intl;
    @service
    abilities;
    @service
    toastFlashMessages;
    @service
    supplierInvoicesManager;
    @service
    modals;
    @service
    errors;
    @service
    router;
    @service
    segment;
    @service
    upsellManager;
    reject = async ()=>{
        this.segment.track('supplier-invoices_reject-button_clicked', {
            origin: 'invoice_details'
        });
        let { supplierInvoice, submitForm, isFormValid } = this.args;
        let submitted = await submitForm.perform();
        if (!submitted || !isFormValid) {
            return;
        }
        this.modals.open('supplier-invoices/modals/decline-invoice', {
            supplierInvoiceId: supplierInvoice.id,
            confirmTask: this.rejectInvoiceTask
        });
    };
    rejectInvoiceTask = dropTask(async (close, declinedNote)=>{
        try {
            // @ts-expect-error
            let { supplierInvoice } = this.args;
            let response;
            if ([
                INVOICE_STATUSES.toApprove,
                INVOICE_STATUSES.awaitingPayment
            ].includes(supplierInvoice.status)) {
                // @ts-expect-error
                response = await this.supplierInvoicesManager.rejectInvoiceTask.perform(supplierInvoice, declinedNote);
            } else if (supplierInvoice.status === INVOICE_STATUSES.toReview) {
                response = await this.supplierInvoicesManager.assignApprovalWorkflowTask.perform(supplierInvoice, 'reject', declinedNote);
            }
            if (response?.supplierInvoice?.status === INVOICE_STATUSES.rejected) {
                this.toastFlashMessages.toastSuccess(this.intl.t('supplier-invoices.toast.rejected'));
            }
        } catch (error) {
            this.errors.handleError(error);
        } finally{
            close();
            this.args.onClose();
        }
    });
    assignApprovalWorkflow = async (action)=>{
        let eventName = action === 'moveToToApprove' ? 'supplier-invoices_move-to-to-approve-button_clicked' : 'supplier-invoices_move-to-to-pay-button_clicked';
        this.segment.track(eventName, {
            origin: 'invoice_details'
        });
        let response = await this.supplierInvoicesManager.assignApprovalWorkflowTask.perform(this.args.supplierInvoice);
        let toastMessage = response?.supplierInvoice?.status === INVOICE_STATUSES.toApprove ? this.intl.t('supplier-invoices.action-toast.moved-to-to-approve') : this.intl.t('supplier-invoices.action-toast.moved-to-to-pay');
        this.args.onClose();
        this.toastFlashMessages.toastSuccess(toastMessage);
        if (this.upsellManager.shouldShowFreemiumUpgrade) {
            this.router.transitionTo('supplier-invoices.freemium-upgrade');
        }
    };
    approveStepTask = async ()=>{
        this.segment.track('supplier-invoices_approve-button_clicked', {
            origin: 'invoice_details'
        });
        let { supplierInvoice, submitForm, isFormValid } = this.args;
        let submitted = await submitForm.perform();
        if (!submitted || !isFormValid) {
            return;
        }
        let lastStep = false;
        if (supplierInvoice?.status === INVOICE_STATUSES.toApprove) {
            let response = await this.supplierInvoicesManager.approveInvoiceTask.perform(supplierInvoice);
            lastStep = response?.requestSupplierInvoice?.lastStep;
        } else if (supplierInvoice?.status === INVOICE_STATUSES.toReview) {
            let response = await this.supplierInvoicesManager.assignApprovalWorkflowTask.perform(supplierInvoice, 'approve');
            lastStep = response?.supplierInvoice?.status === INVOICE_STATUSES.awaitingPayment;
        }
        let toastMessage = lastStep ? this.intl.t('supplier-invoices.toast.success.approved-to-pay') : this.intl.t('supplier-invoices.toast.success.approved-to-approve');
        this.args.onClose();
        this.toastFlashMessages.toastSuccess(toastMessage);
    };
    payByTransferTask = async ()=>{
        let { payByTransferTask } = this.args;
        this.segment.track('supplier-invoices_pay-button_clicked', {
            origin: 'invoice_details'
        });
        await payByTransferTask.perform();
    };
    markAsPaidTask = async ()=>{
        this.segment.track('supplier-invoices_mark-as-paid-button_clicked', {
            origin: 'invoice_details'
        });
        let { markAsPaidTask } = this.args;
        await markAsPaidTask.perform();
    };
    moveToToPay = async ()=>{
        let { submitForm } = this.args;
        if (submitForm) {
            let submitted = await submitForm.perform();
            if (!submitted) return;
        }
        this.assignApprovalWorkflow('moveToToPay');
    };
    get approvalWorkflowState() {
        return this.args.approvalWorkflowState;
    }
    get items() {
        return [
            ...(this.showPay ? [
                this.payItem
            ] : []),
            ...(this.showMoveToToPay ? [
                this.moveToToPayItem
            ] : []),
            ...(this.showApprove ? [
                this.approveItem
            ] : []),
            ...(this.showMoveToToApprove ? [
                this.moveToToApproveItem
            ] : []),
            ...(this.showMarkAsPaid ? [
                this.markAsPaidItem
            ] : [])
        ];
    }
    get ctaItem() {
        return this.items.at(0);
    }
    get dropdownItems() {
        return this.items.slice(1);
    }
    get payItem() {
        return {
            label: this.intl.t('supplier-invoices.cta.pay'),
            onSelect: this.payByTransferTask,
            dataTestId: 'sidebar-pay-button'
        };
    }
    get moveToToPayItem() {
        return {
            label: this.intl.t('supplier-invoices.cta.move-to-to-pay'),
            onSelect: this.moveToToPay,
            dataTestId: 'sidebar-move-to-to-pay-button'
        };
    }
    get markAsPaidItem() {
        return {
            label: this.intl.t('supplier-invoices.cta.mark-as-paid'),
            onSelect: this.markAsPaidTask,
            dataTestId: 'sidebar-mark-as-paid-button'
        };
    }
    get approveItem() {
        return {
            label: this.intl.t('supplier-invoices.cta.approve'),
            onSelect: this.approveStepTask,
            dataTestId: 'sidebar-approve-button'
        };
    }
    get moveToToApproveItem() {
        return {
            label: this.intl.t('supplier-invoices.cta.move-to-to-approve'),
            onSelect: ()=>this.assignApprovalWorkflow('moveToToApprove'),
            dataTestId: 'sidebar-move-to-to-approve-button'
        };
    }
    // what items to show
    get showPay() {
        return (!this.isCurrentStepBroken && this.canUpdateSupplierInvoice && (this.hasStatusToReview || this.hasStatusAwaitingPayment) && ((this.userIsCurrent && this.isCurrentStepToPay) || !this.hasApprovalWorkflow) && this.canPay);
    }
    get showMoveToToPay() {
        return (!this.isCurrentStepBroken && this.canUpdateSupplierInvoice && this.hasStatusToReview && (this.isCurrentStepToPay || !this.hasApprovalWorkflow));
    }
    get showMarkAsPaid() {
        return (!this.isCurrentStepBroken && this.canUpdateSupplierInvoice && (this.hasStatusToReview || (this.hasStatusToApprove && this.userIsCurrent) || (this.hasStatusAwaitingPayment && this.userIsCurrent && this.isCurrentStepToPay) || !this.hasApprovalWorkflow));
    }
    get showApprove() {
        return ((!this.isCurrentStepBroken && this.canUpdateSupplierInvoice && (this.hasStatusToReview || this.hasStatusToApprove) && this.userIsCurrent && this.isCurrentStepToApprove) || (this.hasStatusToApprove && !this.hasApprovalWorkflow));
    }
    get showMoveToToApprove() {
        return (!this.isCurrentStepBroken && this.canUpdateSupplierInvoice && this.hasStatusToReview && this.isCurrentStepToApprove);
    }
    get showReject() {
        return (!this.isCurrentStepBroken && this.canUpdateSupplierInvoice && (this.hasStatusToReview || this.hasStatusToApprove || this.hasStatusAwaitingPayment) && (this.userIsCurrent || !this.hasApprovalWorkflow));
    }
    // show helper functions
    get canUpdateSupplierInvoice() {
        return this.abilities.can('update supplier-invoice');
    }
    get hasStatusToReview() {
        return this.args.supplierInvoice.status === INVOICE_STATUSES.toReview;
    }
    get hasStatusToApprove() {
        return this.args.supplierInvoice.status === INVOICE_STATUSES.toApprove;
    }
    get hasStatusAwaitingPayment() {
        return this.args.supplierInvoice.status === INVOICE_STATUSES.awaitingPayment;
    }
    get userIsCurrent() {
        return (this.approvalWorkflowState && userIsCurrent(this.approvalWorkflowState, this.args.membershipId));
    }
    get isCurrentStepToPay() {
        return this.approvalWorkflowState && isCurrentStepToPay(this.approvalWorkflowState);
    }
    get isCurrentStepToApprove() {
        return this.approvalWorkflowState && isCurrentStepToApprove(this.approvalWorkflowState);
    }
    get hasApprovalWorkflow() {
        return Boolean(this.approvalWorkflowState);
    }
    get isCurrentStepBroken() {
        if (!this.approvalWorkflowState) return false;
        let step = currentStep(this.approvalWorkflowState);
        return step ? isEmpty(step.value) : false;
    }
    get canPay() {
        return this.abilities.can('create transfer');
    }
    get isDisabled() {
        return ((!this.approvalWorkflowState || this.isCurrentStepToPay) && this.args.isPayByTransferCtaDisabled);
    }
    get tooltipText() {
        return ((!this.approvalWorkflowState || this.isCurrentStepToPay) && this.args.payByTransferTooltipText);
    }
    static{
        template_843f424469a1457d903d5917cebe863b(`
    {{#if @isApprovalWorkflowStateLoading}}
      <ReactBridge
        @reactComponent={{SkeletonLoader.Block}}
        @props={{hash width='92px' height='32px' borderRadius='4px'}}
      />

      <ReactBridge
        @reactComponent={{SkeletonLoader.Block}}
        @props={{hash width='81px' height='32px' borderRadius='4px'}}
      />
    {{else if this.items.length}}
      <Tooltip
        @message={{this.tooltipText}}
        @disabled={{or (not this.isDisabled) (not this.tooltipText)}}
        @position='top center'
        data-test-details-dropdown-tooltip
      >
        <ReactBridgeWithProviders
          @reactComponent={{DropdownButton}}
          @props={{hash
            items=this.dropdownItems
            onPress=this.ctaItem.onSelect
            isDisabled=this.isDisabled
            dataTestId=this.ctaItem.dataTestId
          }}
        >
          {{this.ctaItem.label}}
        </ReactBridgeWithProviders>
      </Tooltip>
      {{#if this.showReject}}
        <ReactBridge
          @reactComponent={{Button}}
          @props={{hash
            data-testid='sidebar-reject-button'
            variant='secondary'
            size='small'
            onPress=this.reject
          }}
        >
          {{t 'supplier-invoices.cta.reject'}}
        </ReactBridge>
      {{/if}}
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
