export default {
  "review": "cUL",
  "review-content": "cUT",
  "review-parameters": "cUz",
  "row": "cUa",
  "row__border": "cUH",
  "label": "cUO",
  "value": "cUm",
  "error": "cUp",
  "tooltip-icon": "cUx",
  "terms-link": "cUw"
};
