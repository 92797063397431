export default {
  "label-select": "cut",
  "puce": "cuM",
  "puce-wrapper": "cuP",
  "puce-container": "cul",
  "readonly": "cuX",
  "label-display": "cuC",
  "label-list-name": "cuk body-1",
  "label-list-value": "cuJ body-2"
};
