export default {
  "modal": "cjC",
  "container": "cjk",
  "form-container": "cjJ",
  "preview-container": "cjs",
  "preview-content": "cjF",
  "switch-container": "cjg",
  "supplier-invoice-preview": "cjD",
  "header": "cjV",
  "footer": "cjL",
  "form-content": "cjT",
  "form-content-section": "cjz",
  "form-disclaimer": "cja",
  "hidden": "cjH"
};
