export default {
  "details": "qcF",
  "details-header": "qcg",
  "details-header-amount": "qcD",
  "details-content": "qcV",
  "details-footer": "qcL",
  "invoice-status-container": "qcT body-2",
  "invoice-dates": "qcz",
  "overdue": "qca",
  "details-list": "qcH",
  "details-list-item": "qcO",
  "details-list-item-label": "qcm body-2",
  "details-list-item-value": "qcp",
  "border-top": "qcx",
  "description": "qcw",
  "description-value": "qcN",
  "edit-button": "qcv body-2",
  "edit-icon": "qco",
  "badge-union": "qcn",
  "edit-description": "qci",
  "amount-disclaimer": "qcA",
  "strike-amount": "qcY"
};
