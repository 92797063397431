export default {
  "wrapper": "cjc",
  "content": "cjq",
  "title": "cjZ",
  "aside": "cjR",
  "description": "cjQ",
  "faq-link": "cje",
  "close-btn": "cjB",
  "container": "cjE",
  "mandate-field-list": "cjd",
  "mandate-field-item": "cjI",
  "mandate-field-item__dot": "cjt",
  "mandate-field-item__text": "cjM"
};
