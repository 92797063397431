export default {
  "form": "qqI",
  "form-title": "qqt title2",
  "row": "qqM",
  "col": "qqP",
  "actions": "qql",
  "buttons": "qqX",
  "collapsible-timeline": "qqC",
  "link": "qqk",
  "timeline-status-label": "qqJ",
  "section": "qqs",
  "einvoicing-disclaimer": "qqF",
  "disclaimer-section": "qqg qqs",
  "related-documents-card-margin-top": "qqD",
  "my-24": "qqV"
};
