export default {
  "row": "cum",
  "disabled": "cup",
  "cell": "cux body-2",
  "cell-link": "cuw",
  "cell-content": "cuN",
  "item-truncate-text": "cuv",
  "item-name-cell": "cuo",
  "item-account-cell": "cun",
  "icon": "cui",
  "icon-download": "cuA"
};
